import React from "react";


//Home
const Home = React.lazy(() => import("./pages/Home/index"));

//Company
const PageAboutus = React.lazy(() => import("./pages/Pages/Company/PageAboutus"));
const PageHistory = React.lazy(() => import("./pages/Pages/Company/PageHistory"));
const PageCi = React.lazy(() => import("./pages/Pages/Company/PageCi"));

//Solutions
const PageSolutions = React.lazy(() => import("./pages/Pages/Solutions/PageSolutions"));

//Press
const Press = React.lazy(() => import("./pages/Pages/Press/PagePress"));
const PressDetail = React.lazy(() => import("./pages/Pages/Press/PagePressDetail"));

//Contact
const PageContact = React.lazy(() => import("./pages/Pages/Contact/PageContact"));

//Utility
const PagePrivacy = React.lazy(() => import("./pages/Pages/Utility/PagePrivacy"));
const PageTerms = React.lazy(() => import("./pages/Pages/Utility/PageTerms"));

//Special
const PageComingSoon = React.lazy(() => import("./pages/Pages/Special/PageComingSoon"));
const PageError = React.lazy(() => import("./pages/Pages/Special/PageError"));
const PageMaintenance = React.lazy(() => import("./pages/Pages/Special/PageMaintenance"));

const routes = [
  //routes without Layout

  //Home
  { path: "/", component: Home },
  { path: "/index", component: Home },

  //Company
  { path: "/aboutus", component: PageAboutus },
  { path: "/history", component: PageHistory },
  { path: "/ci", component: PageCi },

  //Solutions
  { path: "/solutions", component: PageSolutions },

  //Press
  { path: "/press", component: Press },
  { path: "/press-detail", component: PressDetail },

  //Contact
  { path: "/contact", component: PageContact },

  //Utility
  { path: "/terms", component: PageTerms },
  { path: "/privacy", component: PagePrivacy },

  //Special Pages
  {
    path: "/page-comingsoon",
    component: PageComingSoon,
    isWithoutLayout: true,
  },
  { path: "/page-error", component: PageError, isWithoutLayout: true },
  {
    path: "/page-maintenance",
    component: PageMaintenance,
    isWithoutLayout: true,
  }
];

export default routes;
