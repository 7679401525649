import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import {
  Container,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "reactstrap";

import { get, map } from "lodash";
import { withTranslation } from "react-i18next";

//i18n
import i18n from "../../i18n";
import languages from "../../locales/languages";

//Import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";

const Topbar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useState("en"); //EN으로 우선 고정
  const [navLinks, setNavLinks] = useState(
    [
      //Note : each child and nested child must have unique id
      { id: 1, title: "Home", link: "/" },
      {
        id: 2,
        title: "Company",
        link: "/#",
        isOpenSubMenu: false,
        child: [
          { title: "About Us", link: "/aboutus" },
          { title: "History", link: "/history" },
          { title: "CI", link: "/ci" },
        ],
      },
      { id: 3, title: "Solutions", link: "/solutions" },
      { id: 4, title: "Press", link: "/press" },
      { id: 5, title: "Contact US", link: "/contact" },
    ]
  );

  const toggleDropdownIsOpen = () => {
    setDropdownIsOpen(!dropdownIsOpen)
  };

  const toggleLine = () => {
    setIsOpen(!isOpen)
  }

  const changeLanguageAction = (lang) => {
    //set language as i18n
    i18n.changeLanguage(lang);
    localStorage.setItem("I18N_LANGUAGE", lang);
    setSelectedLang(lang);
  };

  const activateParentDropdown = useCallback((item) => {
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent1 = parent.parentElement;
      parent1.classList.add("active"); // li
      if (parent1) {
        const parent2 = parent1.parentElement;
        parent2.classList.add("active"); // li
        if (parent2) {
          const parent3 = parent2.parentElement;
          parent3.classList.add("active"); // li
          if (parent3) {
            const parent4 = parent3.parentElement;
            parent4.classList.add("active"); // li
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    let matchingMenuItem = null;
    const ul = document.getElementById("top-menu");
    const items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.location.pathname, activateParentDropdown]);

  const openBlock = (level2_id) => {
    const tmpLinks = navLinks;
    tmpLinks.map((tmpLink) =>
      //Match level 2 id
      tmpLink.id === level2_id
        ? (tmpLink.isOpenSubMenu = !tmpLink.isOpenSubMenu)
        : false
    );
    setNavLinks(tmpLinks)
  };

  return (
    <React.Fragment>
      {/* {props.tagline ? props.tagline : null} */}

      <header id="topnav" className="defaultscroll sticky">
        <Container>
          <div>

            {props.hasDarkTopBar ? (
              <a className="logo" href="/">
                <img src={logodark} height="70" className="logo-light-mode" alt="" />
                <img src={logolight} height="70" className="logo-dark-mode" alt="" />
              </a>
            ) :
              <a className="logo" href="/">
                <span className="logo-light-mode">
                  <img src={logodark} className="l-dark" height="70" alt="" />
                  <img src={logolight} className="l-light" height="70" alt="" />
                </span>
                <img src={logolight} height="70" className="logo-dark-mode" alt="" />
              </a>
            }
          </div>
          {(() => {
            return (
              <ul className="buy-button list-inline mb-0">
                <li className="list-inline-item mb-0">
                  <Dropdown
                    color="primary"
                    isOpen={dropdownIsOpen}
                    toggle={toggleDropdownIsOpen}
                  >
                    <DropdownToggle
                      type="button"
                      className="btn btn-icon btn-muted"
                      style={{fontSize: "12px"}}
                    >
                      {get(languages, `${selectedLang}.sLabel`)}
                    </DropdownToggle>
                    <DropdownMenu
                      direction="left"
                      className="dd-menu bg-white shadow rounded border-0 mt-3 py-3"
                    >
                      {map(Object.keys(languages), (key, idx) => (
                        <DropdownItem
                          key={key}
                          onClick={() => changeLanguageAction(key)}
                          style={{fontSize: "11px", letterSpacing: "0.04em", fontWeight: 700}}
                          className={`${selectedLang === key ? "text-dark" : ""}`}
                          disabled={idx === 0 ? true : false}
                        >
                          {get(languages, `${key}.label`)}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </li>
              </ul>
            );
          })()}

          <div className="menu-extras">
            <div className="menu-item">
              <Link
                to="#"
                onClick={toggleLine}
                className={
                  isOpen ? "navbar-toggle open" : "navbar-toggle"
                }
              >
                <div className="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </Link>
            </div>
          </div>

          <div
            id="navigation"
            style={{ display: isOpen ? "block" : "none" }}
          >
            <ul className="navigation-menu" id="top-menu">
              {navLinks.map((navLink, key) =>
                navLink.child ? (
                  <li className="has-submenu" key={key}>
                    {/* child item(menu Item) - Level 1 */}
                    <Link
                      to={navLink.link}
                      onClick={(event) => {
                        event.preventDefault();
                        openBlock(navLink.id);
                      }}
                    >
                      {navLink.title}
                    </Link>
                    {/* <i className="mdi mdi-chevron-right me-1"></i> */}
                    <span className="menu-arrow"></span>
                    {navLink.isMegaMenu ? (
                      // if menu is mega menu(2 columns grid)
                      <ul
                        className={
                          navLink.isOpenSubMenu
                            ? "submenu megamenu open"
                            : "submenu megamenu"
                        }
                      >
                      </ul>
                    ) : (
                      // if menu is not mega menu(1grid)
                      <ul
                        className={
                          navLink.isOpenSubMenu ? "submenu open" : "submenu"
                        }
                      >
                        {navLink.child.map((childArray, childKey) =>
                          <li key={childKey}>
                            <Link to={childArray.link}>
                              {childArray.title}
                            </Link>
                          </li>
                        )}
                      </ul>
                    )}
                  </li>
                ) : (
                  <li key={key}>
                    <Link to={navLink.link}>{navLink.title}</Link>
                  </li>
                )
              )}
            </ul>
          </div>
        </Container>
      </header>
    </React.Fragment>
  );
}

export default withRouter(Topbar);